.login-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .login-logo {
    margin-top: 50px;
    width: 80px;
  }

  .login-form {
    display: flex;
    align-items: center;
    flex-direction: column;

    input {
      margin-bottom: 15px;
      min-width: 300px;
    }
  }
}
