.edit-post-container {
  padding: 90px;
  @media screen and (max-width: $mobile_size) {
    padding: 20px;
  }
  padding-top: 0px;

  .seo-metadata {
    margin-top: 30px;
    background-color: $light_blue;
    color: $dark_blue;
    border-radius: 10px;
    border: 1px solid $dark_blue;
    padding: 5px 15px;
    margin-bottom: 20px;
    width: fit-content;

    .seo-title {
      background-color: $light_blue;
      border: 1px solid $dark_blue;
      border-radius: 10px;
      position: relative;
      top: -19px;
      width: fit-content;
      padding: 3px 12px;
      font-weight: bold;
    }

    .seo-content {
      margin-top: -20px;

      & > div {
        margin-bottom: 10px;
      }
    }

    label {
      font-weight: bold;
      width: 150px;
      display: block;
      margin-bottom: 3px;
      margin-top: 20px;
    }
  }
}
