.publish-mode-container {
  margin-top: 30px;
  padding: 5px 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  flex-wrap: nowrap;
  width: 250px;
  margin: 0;
  @media screen and (max-width: $mobile_size) {
    margin: 0 auto;
  }

  .publish-mode-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: $dark_blue;
    border-radius: 10px;
    padding: 10px;
    width: 120px;
    margin: 10px;
    cursor: pointer;
    background-color: $light_blue;
    border: 1px solid $light_blue;

    &.active {
      background-color: $white;
      border: 1px solid $dark_blue;
    }

    img {
      width: 40px;
      margin-bottom: 10px;
    }

    label {
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
